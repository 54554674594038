<template>
  <div class="app-members-edit">
    <Module-Header :title="ruleForm.name_tc" :leading="adminUserInfo.position == 2 ? false : true">
      <el-button class="search" @click="toTransaction"><img src="@/assets/images/operation.png" />Transaction</el-button>
      <el-button class="delete" @click="deleteEatery" v-if="adminUserInfo.position == 1"><img src="@/assets/images/delete-01.png" />Delete</el-button>
    </Module-Header>

    <div class="tab-nav">
      <div :class="['nav', eateryGroupCate == 0 && 'select-nav']" @click="eateryGroupCate = 0">Profile</div>
      <div :class="['nav', eateryGroupCate == 1 && 'select-nav']" @click="eateryGroupCate = 1">Summary</div>
    </div>

    <Profile v-if="adminUserInfo.position == 1 && eateryGroupCate == 0" ref="profile" @save="save" :form="ruleForm" type="edit"></Profile>
    <ProfileDetail v-else-if="eateryGroupCate == 0" ref="profile" @save="save" :form="ruleForm" type="edit"></ProfileDetail>

    <Summary v-if="eateryGroupCate == 1" :eateries="ruleForm.eateries"></Summary>
  </div>
</template>

<script>
import { getEateriesGroupDetail, editEateriesGroupDetail, delEateriesGroup } from '@/api/eateryGroup'
import Profile from './components/profile/profile.vue';
import ProfileDetail from './components/profile/profile_detail.vue';
import Summary from './components/summary.vue';

export default {
  components: { Profile, ProfileDetail, Summary },
  data() {
    return {
      eateryGroupCate: 0,
      ruleForm: {},
    }
  }, 
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  methods: {
    toTransaction() {
      let keysWord = this.ruleForm.id
      this.$router.push({path: '/stampTransactions', query: {keysWord, id: 'eatery_group_id'}})
    },
    deleteEatery() {
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            let { id } = this.$route.params
            delEateriesGroup(id).then(() => {
              this.$router.go(-1)
              this.$popUp.removePopup()
            })
          }
        }
      })
    },
    save(ruleForm) {
      let profile = this.$refs.profile,
      obj = {
        id: ruleForm.id
      }
      obj.name = {
        zh_hant: ruleForm.name_tc,
        en: ruleForm.name_en,
      }
      obj.contact = {
        name: ruleForm['person'],
        phone_number: ruleForm['phone_number'],
        email_address: ruleForm['email_address'],
      }
      obj.general = {
        zh_hant: ruleForm['zh_hant'],
          en: ruleForm['en']
      }
      obj.logo = ruleForm['logo']
      obj.user = {
        username: ruleForm['username'],
        password: ruleForm['password'],
      }

      profile.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // console.log(obj)
          editEateriesGroupDetail(obj.id, obj).then(() => {
            this.$message.success('Successfully Saved')
            this.$router.go(-1)
          })
        }
      })
    },

    getDetail(){
      let { id } = this.$route.params
      getEateriesGroupDetail(id).then(v => {
        let data = v.data
        data.eateries.filter(item => {
          item.name_tc = item.name.zhHant
        })
        this.ruleForm = {
          number: data.number,
          id: data.id,
          name_tc: data.name.zhHant,
          name_en: data.name.en,
          logo: data.logo,
          person: data.contact.name,
          phone_number: data.contact.phoneNumber,
          email_address: data.contact.emailAddress,
          zh_hant: data.general.zhHant,
          en: data.general.en,
          eateries: data.eateries,
          publishedAt: data.publishedAt,
          username: data.user.email,
          password: data.user.password,
        }
        
        // console.log(this.ruleForm)
      })
    }
  }, 
  mounted() {
    this.getDetail()
  }
}
</script>

<style scoped lang="scss">
.tab-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
  
  .nav {
    width: calc(50% - .3125rem);
    padding: .625rem;
    text-align: center;
    border-bottom: 3px solid #F0F0EF;
    font-size: 22px;
    font-weight: 700;
    color: #6D6D6D;
    cursor: pointer;
  }

  .select-nav {
    border-bottom-color: #1B7379;
    color: #1B7379;
  }
}
</style>