<template>
  <div class="edit-profile">
    <div class="compulsory" v-if="type == 'edit'">
      <span class="label">Compulsory</span>
      <span class="value">Last Published {{ruleForm.publishedAt | formatDateTime}}</span>
    </div>

    <el-form :model="ruleForm" status-icon ref="ruleForm"
     label-width="260px" class="ruleForm" label-position="left">
      <div class="content">Basic Information</div>
      <el-form-item label="Eatery ID" v-if="type == 'edit'">
        <div class="value">{{ ruleForm.number }}</div>
      </el-form-item>
      <el-form-item label="Eatery Group Name (TC)" prop="name_tc">
        <div class="value">{{ ruleForm.name_tc }}</div>
      </el-form-item>
      <el-form-item label="Eatery group name (EN)" prop="name_en">
        <div class="value">{{ ruleForm.name_en }}</div>
      </el-form-item>
      <el-form-item label="Logo">
        <img :src="ruleForm.logo" alt="" class="logo">
      </el-form-item>

      <div class="content">Contact Information</div>
      <el-form-item label="Contact Person">
        <div class="value">{{ ruleForm.person }}</div>
      </el-form-item>
      <el-form-item label="Contact No.">
        <div class="value">{{ ruleForm.phone_number }}</div>
      </el-form-item>
      <el-form-item label="Contact Email address">
        <span class="value">{{ ruleForm.email_address }}</span>
      </el-form-item>

      <div class="content">Login Information</div>
      <el-form-item label="Username" prop="username">
        <div class="value">{{ ruleForm.username }}</div>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <!-- {{ ruleForm.password }} -->
        <div class="value">****************</div>
      </el-form-item>

      <div class="content">General T&C</div>
      <el-form-item label="Traditional Chinese" prop="zh_hant">
        <div class="value">{{ ruleForm.zh_hant }}</div>
      </el-form-item>
      <el-form-item label="English" prop="en">
        <div class="value">{{ ruleForm.en }}</div>
      </el-form-item>
    </el-form>

    <div class="content" v-if="type == 'edit'">Eatery List under the Group</div>
    <div class="table-box" v-if="type == 'edit'">
      <el-table :data="ruleForm.eateries" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :width="its.width" sortable> </el-table-column>
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <div class="editor">
              <img src="@/assets/images/edit.png" v-if="adminUserInfo.position == 1" @click="toEditPage(scope.row)"/>
              <img src="@/assets/images/details-icon.png" v-else @click="toEditPage(scope.row)"/>
              <!-- <img src="@/assets/images/edit.png" @click="toEditPage(scope.row)"/> -->
              <img src="@/assets/images/delete.png" @click="delEatery(scope.row)" v-if="adminUserInfo.position == 1"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
      />
    </div>
  </div>
</template>

<script>
import { delEateries } from '@/api/eatery'

export default {
  props: {
    type: {
      type: String,
    },
    form: {
      type: Object
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        'name_tc': [
          { required: true, message: 'Please Enter Eatery Group Name (TC)', trigger: 'blur' },
        ],
        'name_en': [
          { required: true, message: 'Please Enter Eatery group name (EN)', trigger: 'blur' },
        ],
        'name': [
          { required: true, message: 'Please Enter User Name', trigger: 'blur' },
        ],
        'password': [
          { required: true, message: 'Please Enter Password', trigger: 'blur' },
        ],
        'zh_hant': [
          { required: true, message: 'Please Enter Traditional Chinese', trigger: 'blur' },
        ],
        'en': [
          { required: true, message: 'Please Enter English', trigger: 'blur' },
        ]
      },

      tableCate: [
        {label: 'Eatery ID', prop: 'number', width: '270'},
        {label: 'Name', prop: 'name_tc', width: ''},
      ],
      total: 0,
      listQ:{
        _start: 1,
        _limit: 100,
      },

      popUp_switch: false
    }
  }, 
  methods: {
    save() {
      let {ruleForm} = this

      this.$emit('save', ruleForm)
    },

    toEditPage(row) {
      this.$router.push({ path: `/eatery/edit/${row.id}`})
    },
    delEatery(row) {
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            delEateries(row.id).then(() => {
              this.$router.go(0)
            })
          }
        }
      })
    }
  },
  watch: {
    form: {
      handler: function() {
        this.ruleForm = JSON.parse(JSON.stringify(this.form))
      },
      deep: true
    }
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  created() {
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
  }
}
</script>

<style scoped lang="scss">
.edit-profile {
  width: 100%;

  .compulsory {
    font-size: 1.125rem;
    color:#1B7379;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.1875rem;
    margin-top: 1.875rem;
  }
  .ruleForm {
    margin-top: 1.875rem;
    width: 67%;

    .value {
      font-size: 1.125rem;
    }

    .logo {
      width: 11.25rem;
      height: 11.25rem;
      border-radius: .9375rem;
      border: 1px solid #6D6D6D;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F9F9F9;
      .icon {
        width: 2.5rem;
        height: auto;
        transform: rotateZ(45deg);
      }
    }
  }
  .content {
    width: 100%;
    color:#1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  .table-box{
    width:100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .editor {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 50px;
        height: auto;
        cursor: pointer;
      }
    }
  }
} 
</style>